<template>
  <base-section
    id="k-d-a-teams-member"
    space="80"
    style="background-color: #fbfbfb"
    :top-inner-shadow="!g_bLowestBr"
  >
    <v-container class="py-0 px-7 px-md-0">
      <v-row no-gutters align="center" style="gap: 12px" class="px-0 px-sm-11">
        <v-col cols="auto">
          <v-btn icon elevation="1" class="main-bw-300" @click="prev">
            <v-icon color="white">mdi-arrow-left</v-icon>
          </v-btn>
        </v-col>

        <v-col>
          <h1 :class="`text-center kda-ts-${g_bLowestBr ? '16' : '28'}pt wt-extrabold`">
            {{ m_arrMembers[iMemberIndex].strTitle }}
          </h1>
        </v-col>

        <v-col cols="auto">
          <v-btn icon elevation="1" class="main-bw-300" @click="next">
            <v-icon color="white">mdi-arrow-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-window v-model="iMemberIndex">
        <v-window-item
          v-for="(memberContainer, index) in m_arrMembers"
          :key="`window-slider-container-${index + 1}`"
          eager
        >
          <v-row
            no-gutters
            justify="center"
            class="mt-8"
            :style="{ rowGap: '60px', columnGap: g_bBaseBr && !g_bLowestBr ? '12px' : '0' }"
          >
            <v-col
              v-for="member in memberContainer.arrItems"
              :key="`${member.strName}-${member.strRole}`"
              class="col-6 col-sm-4 col-md-3"
            >
              <v-card
                elevation="0"
                :max-width="g_bLowestBr ? 138 : 200"
                :min-height="g_bLowestBr ? 180 : 285"
                class="mx-auto"
                color="transparent"
                tile
                flat
              >
                <base-img :src="member.imgSrc" cover :height="g_bLowestBr ? 144 : 240"></base-img>

                <h4
                  :class="`kda-ts-${
                    g_bLowestBr ? '12' : '20'
                  }pt wt-extrabold main-bw-400--text nunito mt-3`"
                >
                  {{ member.strName }}
                </h4>

                <p :class="`kda-ts-${g_bLowestBr ? '10' : '14'}pt nunito main-bw-400--text`">
                  {{ member.strRole }}
                </p>
              </v-card>
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>
    </v-container>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';
import BusinessDetails from '@/mixins/business-details';
import TextClass from '@/mixins/text-class';

export default {
  name: 'KDATeamsMember',

  mixins: [TextClass, BusinessDetails, BaseBreakpoint],

  data() {
    return { iMemberIndex: 0 };
  },

  computed: {
    m_arrMembers() {
      const arrResult = [];

      arrResult.push({
        strTitle: this.$vuetify.lang.t('$vuetify.kda.teams.members.group1'),
        arrItems: [
          {
            strName: 'Reno Septa Pradana',
            strRole: 'Lead Programmer',
            imgName: 'Renoo'
          },
          {
            strName: 'Cleonart Azarya Edhard',
            strRole: 'Backend Engineer',
            imgName: 'Cleonartt'
          },
          {
            strName: 'Ivan Rivaldi',
            strRole: 'Backend Engineer',
            imgName: 'IvanR'
          },
          {
            strName: 'Muhammad Zaenal Abidin',
            strRole: 'Fullstack Programmer',
            imgName: 'Muh Zaenal Abidinn'
          },
          {
            strName: 'Elfina Maulidia',
            strRole: 'Front End Engineer',
            isFemale: true,
            imgName: 'Elfinaa'
          },
          {
            strName: 'Yoghi Yuna Burnama',
            strRole: 'Front End Engineer',
            imgName: 'Yoghii'
          },
          {
            strName: 'Ikhsan Muhammad Nurdin',
            strRole: 'Quality Assurance',
            imgName: 'IkhsanNurdin'
          },
          {
            strName: 'Hamim Fathul Aziz',
            strRole: 'Backend Engineer',
            imgName: 'HamimA'
          }
        ]
      });

      arrResult.push({
        strTitle: this.$vuetify.lang.t('$vuetify.kda.teams.members.group1'),
        arrItems: [
          {
            strName: 'Carvey Chennico',
            strRole: 'Front End Engineer',
            imgName: 'Carveyy'
          },
          {
            strName: 'Muhammad Ibram Gusti',
            strRole: 'Front End Engineer',
            imgName: 'Ibramm'
          },
          {
            strName: 'Samir',
            strRole: 'Front End Engineer',
            imgName: 'Samirr'
          },
          {
            strName: 'Alfredo Lubis ',
            strRole: 'Odoo Developer',
            imgName: 'Alfredo'
          },
          {
            strName: 'Zinedine Kahlil Gibran Zidane',
            strRole: 'Odoo Developer',
            imgName: 'Zinedinee'
          },
          {
            strName: 'Muchamad Maroqi Abdul Jalil',
            strRole: 'Front End Engineer',
            imgName: 'Jalill'
          },
          {
            strName: 'Afif Izzul Falakh',
            strRole: 'Odoo Developer',
            imgName: 'Afiff'
          }
        ]
      });

      arrResult.push({
        strTitle: this.$vuetify.lang.t('$vuetify.kda.teams.members.group2'),
        arrItems: [
          {
            strName: 'Abigail Claudione ',
            strRole: 'Digital Marketing',
            isFemale: true,
            imgName: 'Abigaill'
          }
        ]
      });

      arrResult.push({
        strTitle: this.$vuetify.lang.t('$vuetify.kda.teams.members.group3'),
        arrItems: [
          {
            strName: 'Kenneth Nursalim',
            strRole: 'CEO/CTO',
            imgName: 'KennethN'
          },
          {
            strName: 'Fiber Desy Wijaya',
            strRole: 'HRD',
            isFemale: true,
            imgName: 'Desii'
          },
          {
            strName: 'Morgan Limanjaya',
            strRole: 'Business Development',
            imgName: 'Morgann'
          },
          {
            strName: 'Iqoniyama',
            strRole: 'Business Development',
            imgName: 'Iqoo'
          },
          {
            strName: 'Hendry',
            strRole: 'Project Manager',
            imgName: 'Hendryy'
          },
          {
            strName: 'Kevin Woe',
            strRole: 'Project Manager',
            imgName: 'Kevinn'
          },
          {
            strName: 'Regina Swasti',
            strRole: 'Legal Officer',
            isFemale: true,
            imgName: 'Reginaa'
          },
          {
            strName: 'Evita',
            strRole: 'Tax Accounting',
            isFemale: true,
            imgName: 'Evitaa'
          }
        ]
      });

      arrResult.push({
        strTitle: this.$vuetify.lang.t('$vuetify.kda.teams.members.group4'),
        arrItems: [
          {
            strName: 'Rizky yohanes',
            strRole: 'Lead Desainer/UI UX',
            imgName: 'Rizkyy'
          },
          {
            strName: 'Pramaishella Kastika Putri',
            strRole: 'UI UX Designer',
            isFemale: true,
            imgName: 'Shellaa'
          }
        ]
      });

      for (let i = 0; i < arrResult.length; i++) {
        for (let j = 0; j < arrResult[i].arrItems.length; j++) {
          if (arrResult[i].arrItems[j].imgName && arrResult[i].arrItems[j].imgName !== '') {
            arrResult[i].arrItems[
              j
            ].imgSrc = require(`@/assets/photos/teams/${arrResult[i].arrItems[j].imgName}.jpg`);
          } else {
            arrResult[i].arrItems[j].imgSrc = require(`@/assets/photos/teams-member${
              arrResult[i].arrItems[j].isFemale ? '-fe' : ''
            }.png`);
          }
        }
      }

      return arrResult;
    }
  },

  methods: {
    next() {
      this.iMemberIndex =
        this.iMemberIndex + 1 === this.m_arrMembers.length ? 0 : this.iMemberIndex + 1;
    },
    prev() {
      this.iMemberIndex =
        this.iMemberIndex - 1 < 0 ? this.m_arrMembers.length - 1 : this.iMemberIndex - 1;
    }
  }
};
</script>
